@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');

.root {
  height: 100vh;
}

html {
  background: #272a37;
  color: #9aa5b5;
}

body, button, input, select, option {
  font-family: 'Open Sans' !important;
  letter-spacing: .5px;
}

button {
  padding: 3px 15px;
  border-radius: 20px;
  border: 2px solid #0a84ff;
  background: rgba(10, 132, 255, .2);
  color: #fff;
  margin-left: 3px;
  margin-right: 3px;
  font-weight: 700;
}

button:hover, svg:hover {
  cursor: pointer;
}

input, select {
  margin-top: 5px;
  margin-bottom: 5px;
}

input[type="date"] {
  width: 315px;
}

select {
  width: 80%;
  min-height: 24px;
}

svg {
  width: 10%;
}

input[type="number" i] {
  padding-left: 3px;
  width: 311px;
}

select, input { 
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;   
  -webkit-border-radius: 0;  /* Safari 3-4, iOS 1-3.2, Android 1.6- */    
  -moz-border-radius:    0;  /* Firefox 1-3.6 */     
  border-radius:         3px;  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border: none;
  color: #1e1e1e;
}

.form-container {
  height: 100%;
  width: 320px;
  margin: auto;
  margin-top: 50px;
}

.add-edit-container span {
  margin: 0 8px;
}

#add-member-container, #edit-member-container {
  max-height: 0;
  visibility: hidden;
}

#add-member-container.show, #edit-member-container.show {
  transition: max-height .5s ease-in;
  max-height: 200px;
  visibility: visible;
}

#tithe-list {
  text-align: center;
  margin-top: 10px;
}

#tithe-list h1 {
  margin: 10px 0;
}

#tithe-list .each-tithe {
  display: inline-block;
  min-width: 285px;
  font-size: 13px;
  text-align: left;
}

#tithe-list .delete-each {
  width: 18px;
  color: #ff2d55;
  margin-left: 7px;
}

#tithe-list .each-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
}

/* REPORT SECTION */
.report-container {
  font-size: 14px;
  margin-top: 50px;
}

.report-container .button-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.report-container .daily-container .column-names #date-column {
  width: 110px;
}

.report-container .monthly-container .column-names #date-column {
  width: 130px;
}

.report-container .yearly-container .column-names #date-column {
  width: 70px;
}

.report-container .daily-container .column-names #total-column {
  margin-left: 5px;
}

.report-container > div {
  width: 100% !important;
}

.report-layout-container div:first-child > div > div > div, .report-layout-container div:last-child > div > div {
  display: flex;
  height: auto !important;
}

.report-container #report-name {
  display: inline-block;
  width: 240px;
}

.report-container .report-layout-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 700px;
  margin: auto;
}

.report-layout-container > div {
  margin-left: 10px;
  margin-right: 10px;
}

.daily-container, .monthly-container, .yearly-container {
  margin-bottom: 50px;
}

.date-link, .total-amount {
  display: inline-block;
  width: 150px;
}

.yearly-container .date-link {
  width: 120px;
}

.yearly-container .total-amount {
  width: 170px;
}

@media(max-width: 680px) {
  .report-container .report-layout-container {
    justify-content: center;
  }
}

@media(max-width: 400px) {
  .report-layout-container div:first-child > div > div > div, .report-layout-container div:last-child > div > div {
    justify-content: center;
  }

  .report-layout-container #report-name {
    width: 230px;
  }

  .report-layout-container #report-amount {
    width: 60px;
  }

  .date-link, .total-amount {
    width: 130px;
  }

  .yearly-container .date-link {
    width: 100px;
  }

  .yearly-container .total-amount {
    width: 150px;
  }
}

#view-tithe {
  color: #ff9500;
}

#view-tithe, #delete-tithe {
  font-size: 14px;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}